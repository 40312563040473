import { FormattedMessage, intlShape } from 'react-intl'
import { Link, withIntl } from '../../i18n'
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, buttonIcon, fluidImage } from '../../fragments/fragments'

import Addition from '../../components/technical-benchmark/addition'
import Banner from '../../components/reusables/banner'
import { Box } from 'grommet'
import Centered from '../../components/reusables/centered'
import Contact from '../../components/reusables/contact'
import Img from 'gatsby-image'
import InputButton from '../../components/reusables/inputButton'
import Layout from '../../components/layout'
import Multiplcation from '../../components/technical-benchmark/multiplication'
import Rows from '../../components/reusables/rows'
import Social from '../../components/reusables/social'
import Tabs from '../../components/reusables/tabs'
import { injectIntl } from 'react-intl'

const ContactUs = ({ data }) => {
  return (
    <Layout>
      <Banner data={data} shade={'light'} />
      <Contact index={0} data={data} shade={'light'} />
      <Social index={0} data={data} shade={'grey'} />
    </Layout>
  )
}

export default withIntl(ContactUs)

export const imagesQuery = graphql`
  query {
    bannerGlobe: file(relativePath: { eq: "hero/bannerGlobe@3x.png" }) {
      ...bannerIcon
    }
    shortLastSection: file(
      relativePath: { eq: "hero/shortLastSection@2x.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twitter: file(relativePath: { eq: "contact/twitter@3x.png" }) {
      childImageSharp {
        fixed(width: 21, height: 18) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    facebook: file(relativePath: { eq: "contact/facebook@3x.png" }) {
      childImageSharp {
        fixed(width: 12 , height: 24) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    telegram: file(relativePath: { eq: "contact/telegram@3x.png" }) {
      childImageSharp {
        fixed(width: 24, height: 22) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    wechat: file(relativePath: { eq: "contact/wechat@3x.png" }) {
      childImageSharp {
        fixed(width: 25, height: 20) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    github: file(relativePath: { eq: "contact/github@3x.png" }) {
      childImageSharp {
        fixed(width: 26, height: 25) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    weibo: file(relativePath: { eq: "contact/weibo@3x.png" }) {
      childImageSharp {
        fixed(width: 29, height: 23) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    reddit: file(relativePath: { eq: "contact/reddit@3x.png" }) {
      childImageSharp {
        fixed(width: 22, height: 19) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    youtube: file(relativePath: { eq: "contact/youtube@3x.png" }) {
      childImageSharp {
        fixed(width: 44, height: 18) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    linkedin: file(relativePath: { eq: "contact/linkedin@3x.png" }) {
      childImageSharp {
        fixed(width: 20, height: 19) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    medium: file(relativePath: { eq: "contact/medium@3x.png" }) {
      childImageSharp {
        fixed(width: 22, height: 17) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    blog: file(relativePath: { eq: "contact/blog@3x.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    kakao_talk: file(relativePath: { eq: "contact/kakao-talk@3x.png" }) {
      childImageSharp {
        fixed(width: 26, height: 24) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
